<template>
    <div>
        <div id="lp-container" class="lp-container">

            <section class="lp-main-team">
                <div class="team">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-6 col-lg-offset-3 text-center">
                                <h2><span class="ion-minus"></span>Investors & Board<span class="ion-minus"></span></h2>
                                <p>All the information for our Investors & Board</p><br>
                            </div>
                        </div>

                        <div class="row text-center">

                            <div class="col-lg-1 hidden-xs"></div>

                            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">

                                <img class="img-rounded" alt="team-photo" :src="require('../../public/img/investors/1.png')" width="100%">

                                <div class="team-member">

                                    <h4>Gin Chao</h4>

                                    <p>Investor & Board Member</p>
                                    <p>Strategy @ NBA</p>

                                </div>
                                <!--
                                    <p class="social">
                                        <a href="#"><span class="fab fa-facebook"></span></a>
                                    </p>
                                                                                         -->
                            </div>
                            <!--col-lg-4 -->

                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                                <img class="img-rounded" alt="team-photo" :src="require('../../public/img/investors/2.png')" width="100%">

                                <div class="team-member">

                                    <h4>Rita Nguyen</h4>

                                    <p>Board Member</p>
                                    <p>Ex EA Sport, Entrepreneur</p>

                                </div>
                            </div>
                            <!--col-lg-4 -->

                            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">

                                <img class="img-rounded" alt="team-photo" :src="require('../../public/img/investors/3.png')" width="100%">

                                <div class="team-member">

                                    <h4>Paul Wilsen</h4>

                                    <p>Investor & Board Member</p>
                                    <p>Ex Microsoft. Amazon </p>

                                </div>
                            </div> <!-- col-lg-4 -->

                            <div class="col-lg-1 hidden-xs"></div>

                        </div> <!-- row text-center -->

                        <div class="row text-center">

                            <div class="col-lg-1 hidden-xs"></div>

                            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">

                                <img class="img-rounded" alt="team-photo" :src="require('../../public/img/investors/4.png')" width="100%">

                                <div class="team-member">

                                    <h4>Field Pickering</h4>

                                    <p>Investor & Board Member</p>
                                    <p>Vulpes Investment</p>

                                </div>
                            </div> <!-- col-lg-4 -->

                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                                <img class="img-rounded" alt="team-photo" :src="require('../../public/img/investors/5.png')" width="100%">

                                <div class="team-member">

                                    <h4>500Startup</h4>

                                    <p>Investor</p>
                                    <p></p>

                                </div>
                            </div> <!-- col-lg-4 -->

                            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">

                                <img class="img-rounded" alt="team-photo" :src="require('../../public/img/investors/6.png')" width="100%">

                                <div class="team-member">

                                    <h4>China COV Capital </h4>

                                    <p>Investor</p>
                                    <p></p>

                                </div>
                            </div> <!-- col-lg-4 -->
                            <div class="col-lg-1 hidden-xs"></div>

                        </div>

                    </div>
                </div>


            </section>
            <!-- container -->

        </div>
    </div>
</template>

<script>
	export default {
		name: "Investor",
	}
</script>

<style scoped>
    @import '../../public/css/bootstrap.min.css';
    @import '../../public/css/bindez.css';
    @import '../../public/css/bindez-custom2.css';

    @font-face {
        font-family: 'icomoon';
        src: url('../../public/fonts/icomoon.eot');
        src: url('../../public/fonts/icomoon.eot') format('embedded-opentype'),
        url('../../public/fonts/icomoon.ttf') format('truetype'),
        url('../../public/fonts/icomoon.woff') format('woff'),
        url('../../public/fonts/icomoon.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    [class^="icon-"],
    [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-burmese:before {
        content: "\e900";
    }

    .icon-data:before {
        content: "\e901";
    }

    .icon-real-time:before {
        content: "\e902";
    }

    #lp-container {
        background-color: #fff;
    }

    .custom-modal-size > * > * {
        height: 500px;
    }
</style>
